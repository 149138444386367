export const WITNESS_REQUEST = "WITNESS_REQUEST";
export const WITNESS_SUCCESS = "WITNESS_SUCCESS";
export const WITNESS_FAILURE = "WITNESS_FAILURE";
export const WITNESS_Reset = "WITNESS_Reset";

export const ADD_WITNESS_REQUEST = "ADD_WITNESS_REQUEST";
export const ADD_WITNESS_SUCCESS = "ADD_WITNESS_SUCCESS";
export const ADD_WITNESS_FAILURE = "ADD_WITNESS_FAILURE";

export const DELETE_WITNESS_REQUEST = "DELETE_WITNESS_REQUEST";
export const DELETE_WITNESS_SUCCESS = "DELETE_WITNESS_SUCCESS";
export const DELETE_WITNESS_FAILURE = "DELETE_WITNESS_FAILURE";