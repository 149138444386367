export const Errors_REQUEST = "Errors_REQUEST";
export const Errors_SUCCESS = "Errors_SUCCESS";
export const Errors_FAILURE = "Errors_FAILURE";

export const Errors_Details_REQUEST = "Errors_Details_REQUEST";
export const Errors_Details_SUCCESS = "Errors_Details_SUCCESS";
export const Errors_Details_FAILURE = "Errors_Details_FAILURE";

export const Users_Ids_REQUEST = "Users_Ids_REQUEST";
export const Users_Ids_SUCCESS = "Users_Ids_SUCCESS";
export const Users_Ids_FAILURE = "Users_Ids_FAILURE";
