import { Errors_Details_FAILURE, Errors_Details_REQUEST, Errors_Details_SUCCESS, Errors_FAILURE, Errors_REQUEST, Errors_SUCCESS, Users_Ids_FAILURE, Users_Ids_REQUEST, Users_Ids_SUCCESS } from "./ErrorsConstant";

export const ErrorsReducer = (
	initialState = {
		Errors: [],
		errorDetails: []
	},
	action
) => {
	switch (action.type) {
		case Errors_REQUEST:
			return {
				...initialState,
				loading: true,
				Errors: [],
			};
		case Errors_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				loading: false,

			};

		case Errors_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
			case Errors_Details_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
				errorDetails: [],
			};
		case Errors_Details_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				detailsLoading: false,

			};

		case Errors_Details_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				getError: action.payload,
			};
			case Users_Ids_REQUEST:
				return {
					...initialState,
					usersLoading: true,
					usersErrorDetails: [],
				};
			case Users_Ids_SUCCESS:
				return {
					...initialState,
					data: action.payload,
					usersLoading: false,
	
				};
	
			case Users_Ids_FAILURE:
				return {
					...initialState,
					usersLoading: false,
					getError: action.payload,
				};
		default:
			return initialState;
	}
};
