import {
	ADD_INCIDENT_PROCURMENT_FAILURE,
	ADD_INCIDENT_PROCURMENT_REQUEST,
	ADD_INCIDENT_PROCURMENT_SUCCESS,
	DELETE_INCIDENT_PROCURMENT_FAILURE,
	DELETE_INCIDENT_PROCURMENT_REQUEST,
	DELETE_INCIDENT_PROCURMENT_SUCCESS,
	INCIDENT_PROCURMENT_FAILURE,
	INCIDENT_PROCURMENT_REQUEST,
	INCIDENT_PROCURMENT_Reset,
	INCIDENT_PROCURMENT_SUCCESS,
	UPDATE_INCIDENT_PROCUREMENT_STATUS_FAILURE,
	UPDATE_INCIDENT_PROCUREMENT_STATUS_REQUEST,
	UPDATE_INCIDENT_PROCUREMENT_STATUS_SUCCESS,
	Update_INCIDENT_PROCURMENT_FAILURE,
	Update_INCIDENT_PROCURMENT_REQUEST,
	Update_INCIDENT_PROCURMENT_SUCCESS,
} from "./IncidentProcurementConstant";

// Incident Procurement
export const IncidentProcurementReducer = (
	initialState = {
		IncidentProcurement: [],
		GetIncidentProcurementLoading: false,
		addIncidentProcurementLoading: false,
		deleteIncidentProcurementLoading: false,
		UpdateIncidentStatusLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident Procurement
		case INCIDENT_PROCURMENT_REQUEST:
			return {
				...initialState,
				GetIncidentProcurementLoading: true,
			};
		case INCIDENT_PROCURMENT_SUCCESS:
			return {
				...initialState,
				IncidentProcurement: action.payload.result.data,
				GetIncidentProcurementLoading: false,
			};
		case INCIDENT_PROCURMENT_FAILURE:
			return {
				...initialState,
				GetIncidentProcurementLoading: false,
				getError: action.payload,
			};
		case INCIDENT_PROCURMENT_Reset:
			return {
				...initialState,
				IncidentProcurement: [],
			};
		// Add Incident Procurment
		case ADD_INCIDENT_PROCURMENT_REQUEST:
			return {
				...initialState,
				addIncidentProcurementLoading: true,
			};
		case ADD_INCIDENT_PROCURMENT_SUCCESS:
			return {
				...initialState,
				addIncidentProcurementLoading: false,
			};
		case ADD_INCIDENT_PROCURMENT_FAILURE:
			return {
				...initialState,
				addIncidentProcurementLoading: false,
				addError: action.payload,
			};

		// Update Incident Procurment
		case Update_INCIDENT_PROCURMENT_REQUEST:
			return {
				...initialState,
				updateIncidentProcurementLoading: true,
			};
		case Update_INCIDENT_PROCURMENT_SUCCESS:
			return {
				...initialState,
				updateIncidentProcurementLoading: false,
			};
		case Update_INCIDENT_PROCURMENT_FAILURE:
			return {
				...initialState,
				updateIncidentProcurementLoading: false,
				updateError: action.payload,
			};

		// Delete Incident Procurment
		case DELETE_INCIDENT_PROCURMENT_REQUEST:
			return {
				...initialState,
				deleteIncidentProcurementLoading: true,
			};
		case DELETE_INCIDENT_PROCURMENT_SUCCESS:
			return {
				...initialState,
				deleteIncidentProcurementLoading: false,
			};
		case DELETE_INCIDENT_PROCURMENT_FAILURE:
			return {
				...initialState,
				deleteIncidentProcurementLoading: false,
				deleteError: action.payload,
			};
		// Update incident Procurement Status
		case UPDATE_INCIDENT_PROCUREMENT_STATUS_REQUEST:
			return {
				...initialState,
				UpdateIncidentStatusLoading: true,
			};
		case UPDATE_INCIDENT_PROCUREMENT_STATUS_SUCCESS:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
			};
		case UPDATE_INCIDENT_PROCUREMENT_STATUS_FAILURE:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
				getError: action.payload,
			};
		default:
			return initialState;
	}
};
