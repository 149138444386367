export const INCIDENT_Menufacturing_REQUEST = "INCIDENT_Menufacturing_REQUEST";
export const INCIDENT_Menufacturing_SUCCESS = "INCIDENT_Menufacturing_SUCCESS";
export const INCIDENT_Menufacturing_FAILURE = "INCIDENT_Menufacturing_FAILURE";
export const INCIDENT_Menufacturing_Reset = "INCIDENT_Menufacturing_Reset";

export const INCIDENT_Menufacturing_Details_REQUEST =
	"INCIDENT_Menufacturing_Details_REQUEST";
export const INCIDENT_Menufacturing_Details_SUCCESS =
	"INCIDENT_Menufacturing_Details_SUCCESS";
export const INCIDENT_Menufacturing_Details_FAILURE =
	"INCIDENT_Menufacturing_Details_FAILURE";

export const ADD_INCIDENT_Menufacturing_REQUEST =
	"ADD_INCIDENT_Menufacturing_REQUEST";
export const ADD_INCIDENT_Menufacturing_SUCCESS =
	"ADD_INCIDENT_Menufacturing_SUCCESS";
export const ADD_INCIDENT_Menufacturing_FAILURE =
	"ADD_INCIDENT_Menufacturing_FAILURE";

export const Update_INCIDENT_Menufacturing_REQUEST =
	"Update_INCIDENT_Menufacturing_REQUEST";
export const Update_INCIDENT_Menufacturing_SUCCESS =
	"Update_INCIDENT_Menufacturing_SUCCESS";
export const Update_INCIDENT_Menufacturing_FAILURE =
	"Update_INCIDENT_Menufacturing_FAILURE";

export const DELETE_INCIDENT_Menufacturing_REQUEST =
	"DELETE_INCIDENT_Menufacturing_REQUEST";
export const DELETE_INCIDENT_Menufacturing_SUCCESS =
	"DELETE_INCIDENT_Menufacturing_SUCCESS";
export const DELETE_INCIDENT_Menufacturing_FAILURE =
	"DELETE_INCIDENT_Menufacturing_FAILURE";

export const UPDATE_INCIDENT_MENUFACTURING_STATUS_REQUEST =
	"UPDATE_INCIDENT_MENUFACTURING_STATUS_REQUEST";
export const UPDATE_INCIDENT_MENUFACTURING_STATUS_SUCCESS =
	"UPDATE_INCIDENT_MENUFACTURING_STATUS_SUCCESS";
export const UPDATE_INCIDENT_MENUFACTURING_STATUS_FAILURE =
	"UPDATE_INCIDENT_MENUFACTURING_STATUS_FAILURE";
