export const INJURED_REQUEST = "INJURED_REQUEST";
export const INJURED_SUCCESS = "INJURED_SUCCESS";
export const INJURED_FAILURE = "INJURED_FAILURE";
export const INJURED_Reset = "INJURED_Reset";

export const ADD_INJURED_REQUEST = "ADD_INJURED_REQUEST";
export const ADD_INJURED_SUCCESS = "ADD_INJURED_SUCCESS";
export const ADD_INJURED_FAILURE = "ADD_INJURED_FAILURE";

export const DELETE_INJURED_REQUEST = "DELETE_INJURED_REQUEST";
export const DELETE_INJURED_SUCCESS = "DELETE_INJURED_SUCCESS";
export const DELETE_INJURED_FAILURE = "DELETE_INJURED_FAILURE";