import {
	ADD_INJURED_FAILURE,
	ADD_INJURED_REQUEST,
	ADD_INJURED_SUCCESS,
	DELETE_INJURED_FAILURE,
	DELETE_INJURED_REQUEST,
	DELETE_INJURED_SUCCESS,
	INJURED_FAILURE,
	INJURED_REQUEST,
	INJURED_Reset,
	INJURED_SUCCESS,
} from "./InjuredConstant";

// Injured
export const InjuredReducer = (
	initialState = {
		Injured: [],
		GetInjuredLoading: false,
		addInjuredLoading: false,
		deleteInjuredLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Injured Employees
		case INJURED_REQUEST:
			return {
				...initialState,
				GetInjuredLoading: true,
			};
		case INJURED_SUCCESS:
			return {
				...initialState,
				Injured: action.payload.result.data,
				GetInjuredLoading: false,
			};
		case INJURED_FAILURE:
			return {
				...initialState,
				GetInjuredLoading: false,
				getError: action.payload,
			};
		case INJURED_Reset:
			return {
				...initialState,
				Injured: [],
			};
		// Add Injured Employees
		case ADD_INJURED_REQUEST:
			return {
				...initialState,
				addInjuredLoading: true,
			};
		case ADD_INJURED_SUCCESS:
			return {
				...initialState,
				addInjuredLoading: false,
			};
		case ADD_INJURED_FAILURE:
			return {
				...initialState,
				addInjuredLoading: false,
				addError: action.payload,
			};

		// Delete Injured Employees
		case DELETE_INJURED_REQUEST:
			return {
				...initialState,
				deleteInjuredLoading: true,
			};
		case DELETE_INJURED_SUCCESS:
			return {
				...initialState,
				deleteInjuredLoading: false,
			};
		case DELETE_INJURED_FAILURE:
			return {
				...initialState,
				deleteInjuredLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};
