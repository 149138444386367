import {
	ADD_INCIDENT_Sample_FAILURE,
	ADD_INCIDENT_Sample_REQUEST,
	ADD_INCIDENT_Sample_SUCCESS,
	DELETE_INCIDENT_Sample_FAILURE,
	DELETE_INCIDENT_Sample_REQUEST,
	DELETE_INCIDENT_Sample_SUCCESS,
	INCIDENT_Sample_Details_FAILURE,
	INCIDENT_Sample_Details_REQUEST,
	INCIDENT_Sample_Details_SUCCESS,
	INCIDENT_Sample_FAILURE,
	INCIDENT_Sample_REQUEST,
	INCIDENT_Sample_Reset,
	INCIDENT_Sample_SUCCESS,
	Update_INCIDENT_Sample_FAILURE,
	Update_INCIDENT_Sample_REQUEST,
	Update_INCIDENT_Sample_SUCCESS,
} from "./IncidentSampleConstant";

// Incident Sample
export const IncidentSampleReducer = (
	initialState = {
		IncidentSample: [],
		GetIncidentSampleLoading: false,
		addIncidentSampleLoading: false,
		deleteIncidentSampleLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident Sample
		case INCIDENT_Sample_REQUEST:
			return {
				...initialState,
				GetIncidentSampleLoading: true,
			};
		case INCIDENT_Sample_SUCCESS:
			return {
				...initialState,
				IncidentSample: action.payload.result.data,
				GetIncidentSampleLoading: false,
			};
		case INCIDENT_Sample_FAILURE:
			return {
				...initialState,
				GetIncidentSampleLoading: false,
				getError: action.payload,
			};
		case INCIDENT_Sample_Reset:
			return {
				...initialState,
				IncidentSample: [],
			};
		// Get Incident Sample Details
		case INCIDENT_Sample_Details_REQUEST:
			return {
				...initialState,
				GetIncidentSampleLoading: true,
			};
		case INCIDENT_Sample_Details_SUCCESS:
			return {
				...initialState,
				IncidentSample: action.payload.result.data,
				GetIncidentSampleLoading: false,
			};
		case INCIDENT_Sample_Details_FAILURE:
			return {
				...initialState,
				GetIncidentSampleLoading: false,
				getError: action.payload,
			};
		// Add Incident Sample
		case ADD_INCIDENT_Sample_REQUEST:
			return {
				...initialState,
				addIncidentSampleLoading: true,
			};
		case ADD_INCIDENT_Sample_SUCCESS:
			return {
				...initialState,
				addIncidentSampleLoading: false,
			};
		case ADD_INCIDENT_Sample_FAILURE:
			return {
				...initialState,
				addIncidentSampleLoading: false,
				addError: action.payload,
			};

		// Update Incident Sample
		case Update_INCIDENT_Sample_REQUEST:
			return {
				...initialState,
				updateIncidentSampleLoading: true,
			};
		case Update_INCIDENT_Sample_SUCCESS:
			return {
				...initialState,
				updateIncidentSampleLoading: false,
			};
		case Update_INCIDENT_Sample_FAILURE:
			return {
				...initialState,
				updateIncidentSampleLoading: false,
				updateError: action.payload,
			};

		// Delete Incident Sample
		case DELETE_INCIDENT_Sample_REQUEST:
			return {
				...initialState,
				deleteIncidentSampleLoading: true,
			};
		case DELETE_INCIDENT_Sample_SUCCESS:
			return {
				...initialState,
				deleteIncidentSampleLoading: false,
			};
		case DELETE_INCIDENT_Sample_FAILURE:
			return {
				...initialState,
				deleteIncidentSampleLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};
