export const INCIDENT_PROCURMENT_REQUEST = "INCIDENT_PROCURMENT_REQUEST";
export const INCIDENT_PROCURMENT_SUCCESS = "INCIDENT_PROCURMENT_SUCCESS";
export const INCIDENT_PROCURMENT_FAILURE = "INCIDENT_PROCURMENT_FAILURE";
export const INCIDENT_PROCURMENT_Reset = "INCIDENT_PROCURMENT_Reset";

export const INCIDENT_PROCURMENT_Details_REQUEST =
	"INCIDENT_PROCURMENT_Details_REQUEST";
export const INCIDENT_PROCURMENT_Details_SUCCESS =
	"INCIDENT_PROCURMENT_Details_SUCCESS";
export const INCIDENT_PROCURMENT_Details_FAILURE =
	"INCIDENT_PROCURMENT_Details_FAILURE";

export const ADD_INCIDENT_PROCURMENT_REQUEST =
	"ADD_INCIDENT_PROCURMENT_REQUEST";
export const ADD_INCIDENT_PROCURMENT_SUCCESS =
	"ADD_INCIDENT_PROCURMENT_SUCCESS";
export const ADD_INCIDENT_PROCURMENT_FAILURE =
	"ADD_INCIDENT_PROCURMENT_FAILURE";

export const Update_INCIDENT_PROCURMENT_REQUEST =
	"Update_INCIDENT_PROCURMENT_REQUEST";
export const Update_INCIDENT_PROCURMENT_SUCCESS =
	"Update_INCIDENT_PROCURMENT_SUCCESS";
export const Update_INCIDENT_PROCURMENT_FAILURE =
	"Update_INCIDENT_PROCURMENT_FAILURE";

export const DELETE_INCIDENT_PROCURMENT_REQUEST =
	"DELETE_INCIDENT_PROCURMENT_REQUEST";
export const DELETE_INCIDENT_PROCURMENT_SUCCESS =
	"DELETE_INCIDENT_PROCURMENT_SUCCESS";
export const DELETE_INCIDENT_PROCURMENT_FAILURE =
	"DELETE_INCIDENT_PROCURMENT_FAILURE";

export const UPDATE_INCIDENT_PROCUREMENT_STATUS_REQUEST =
	"UPDATE_INCIDENT_PROCUREMENT_STATUS_REQUEST";
export const UPDATE_INCIDENT_PROCUREMENT_STATUS_SUCCESS =
	"UPDATE_INCIDENT_PROCUREMENT_STATUS_SUCCESS";
export const UPDATE_INCIDENT_PROCUREMENT_STATUS_FAILURE =
	"UPDATE_INCIDENT_PROCUREMENT_STATUS_FAILURE";
