export const INCIDENT_Supply_Chain_REQUEST = "INCIDENT_Supply_Chain_REQUEST";
export const INCIDENT_Supply_Chain_SUCCESS = "INCIDENT_Supply_Chain_SUCCESS";
export const INCIDENT_Supply_Chain_FAILURE = "INCIDENT_Supply_Chain_FAILURE";
export const INCIDENT_Supply_Chain_Reset = "INCIDENT_Supply_Chain_Reset";

export const INCIDENT_Supply_Chain_Details_REQUEST =
	"INCIDENT_Supply_Chain_Details_REQUEST";
export const INCIDENT_Supply_Chain_Details_SUCCESS =
	"INCIDENT_Supply_Chain_Details_SUCCESS";
export const INCIDENT_Supply_Chain_Details_FAILURE =
	"INCIDENT_Supply_Chain_Details_FAILURE";

export const ADD_INCIDENT_Supply_Chain_REQUEST =
	"ADD_INCIDENT_Supply_Chain_REQUEST";
export const ADD_INCIDENT_Supply_Chain_SUCCESS =
	"ADD_INCIDENT_Supply_Chain_SUCCESS";
export const ADD_INCIDENT_Supply_Chain_FAILURE =
	"ADD_INCIDENT_Supply_Chain_FAILURE";

export const Update_INCIDENT_Supply_Chain_REQUEST =
	"Update_INCIDENT_Supply_Chain_REQUEST";
export const Update_INCIDENT_Supply_Chain_SUCCESS =
	"Update_INCIDENT_Supply_Chain_SUCCESS";
export const Update_INCIDENT_Supply_Chain_FAILURE =
	"Update_INCIDENT_Supply_Chain_FAILURE";

export const DELETE_INCIDENT_Supply_Chain_REQUEST =
	"DELETE_INCIDENT_Supply_Chain_REQUEST";
export const DELETE_INCIDENT_Supply_Chain_SUCCESS =
	"DELETE_INCIDENT_Supply_Chain_SUCCESS";
export const DELETE_INCIDENT_Supply_Chain_FAILURE =
	"DELETE_INCIDENT_Supply_Chain_FAILURE";

export const UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_REQUEST =
	"UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_REQUEST";
export const UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_SUCCESS =
	"UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_SUCCESS";
export const UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_FAILURE =
	"UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_FAILURE";
