import {
	ADD_WITNESS_FAILURE,
	ADD_WITNESS_REQUEST,
	ADD_WITNESS_SUCCESS,
	DELETE_WITNESS_FAILURE,
	DELETE_WITNESS_REQUEST,
	DELETE_WITNESS_SUCCESS,
	WITNESS_FAILURE,
	WITNESS_REQUEST,
	WITNESS_Reset,
	WITNESS_SUCCESS,
} from "./WitnessConstant";

// Witnesses
export const WitnessesReducer = (
	initialState = {
		Witnesses: [],
		GetWitnessLoading: false,
		addWitnessLoading: false,
		deleteWitnessLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Witness 
		case WITNESS_REQUEST:
			return {
				...initialState,
				GetWitnessLoading: true,
			};
		case WITNESS_SUCCESS:
			return {
				...initialState,
				Witnesses: action.payload.result.data,
				GetWitnessLoading: false,
			};
		case WITNESS_FAILURE:
			return {
				...initialState,
				GetWitnessLoading: false,
				getError: action.payload,
			};
		case WITNESS_Reset:
			return {
				...initialState,
				Witnesses: [],
			};
		// Add Witness 
		case ADD_WITNESS_REQUEST:
			return {
				...initialState,
				addWitnessLoading: true,
			};
		case ADD_WITNESS_SUCCESS:
			return {
				...initialState,
				addWitnessLoading: false,
			};
		case ADD_WITNESS_FAILURE:
			return {
				...initialState,
				addWitnessLoading: false,
				addError: action.payload,
			};

		// Delete Witness 
		case DELETE_WITNESS_REQUEST:
			return {
				...initialState,
				deleteWitnessLoading: true,
			};
		case DELETE_WITNESS_SUCCESS:
			return {
				...initialState,
				deleteWitnessLoading: false,
			};
		case DELETE_WITNESS_FAILURE:
			return {
				...initialState,
				deleteWitnessLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};
