import {
	ADD_INCIDENT_Menufacturing_FAILURE,
	ADD_INCIDENT_Menufacturing_REQUEST,
	ADD_INCIDENT_Menufacturing_SUCCESS,
	DELETE_INCIDENT_Menufacturing_FAILURE,
	DELETE_INCIDENT_Menufacturing_REQUEST,
	DELETE_INCIDENT_Menufacturing_SUCCESS,
	INCIDENT_Menufacturing_FAILURE,
	INCIDENT_Menufacturing_REQUEST,
	INCIDENT_Menufacturing_Reset,
	INCIDENT_Menufacturing_SUCCESS,
	UPDATE_INCIDENT_MENUFACTURING_STATUS_FAILURE,
	UPDATE_INCIDENT_MENUFACTURING_STATUS_REQUEST,
	UPDATE_INCIDENT_MENUFACTURING_STATUS_SUCCESS,
	Update_INCIDENT_Menufacturing_FAILURE,
	Update_INCIDENT_Menufacturing_REQUEST,
	Update_INCIDENT_Menufacturing_SUCCESS,
} from "./IncidentMenufacturingConstant";

// Incident Menufacturing
export const IncidentMenufacturingReducer = (
	initialState = {
		IncidentMenufacturing: [],
		GetIncidentMenufacturingLoading: false,
		addIncidentMenufacturingLoading: false,
		deleteIncidentMenufacturingLoading: false,
		UpdateIncidentStatusLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident Supply_Chain
		case INCIDENT_Menufacturing_REQUEST:
			return {
				...initialState,
				GetIncidentMenufacturingLoading: true,
			};
		case INCIDENT_Menufacturing_SUCCESS:
			return {
				...initialState,
				IncidentSupply_Chain: action.payload.result.data,
				GetIncidentMenufacturingLoading: false,
			};
		case INCIDENT_Menufacturing_FAILURE:
			return {
				...initialState,
				GetIncidentMenufacturingLoading: false,
				getError: action.payload,
			};
		case INCIDENT_Menufacturing_Reset:
			return {
				...initialState,
				IncidentMenufacturing: [],
			};
		// Add Incident Supply_Chain
		case ADD_INCIDENT_Menufacturing_REQUEST:
			return {
				...initialState,
				addIncidentMenufacturingLoading: true,
			};
		case ADD_INCIDENT_Menufacturing_SUCCESS:
			return {
				...initialState,
				addIncidentMenufacturingLoading: false,
			};
		case ADD_INCIDENT_Menufacturing_FAILURE:
			return {
				...initialState,
				addIncidentMenufacturingLoading: false,
				addError: action.payload,
			};

		// Update Incident Supply_Chain
		case Update_INCIDENT_Menufacturing_REQUEST:
			return {
				...initialState,
				updateIncidentMenufacturingLoading: true,
			};
		case Update_INCIDENT_Menufacturing_SUCCESS:
			return {
				...initialState,
				updateIncidentMenufacturingLoading: false,
			};
		case Update_INCIDENT_Menufacturing_FAILURE:
			return {
				...initialState,
				updateIncidentMenufacturingLoading: false,
				updateError: action.payload,
			};

		// Delete Incident Supply_Chain
		case DELETE_INCIDENT_Menufacturing_REQUEST:
			return {
				...initialState,
				deleteIncidentMenufacturingLoading: true,
			};
		case DELETE_INCIDENT_Menufacturing_SUCCESS:
			return {
				...initialState,
				deleteIncidentMenufacturingLoading: false,
			};
		case DELETE_INCIDENT_Menufacturing_FAILURE:
			return {
				...initialState,
				deleteIncidentMenufacturingLoading: false,
				deleteError: action.payload,
			};
		
		// Update Incident Menufacturing
		case UPDATE_INCIDENT_MENUFACTURING_STATUS_REQUEST:
			return {
				...initialState,
				UpdateIncidentStatusLoading: true,
			};
		case UPDATE_INCIDENT_MENUFACTURING_STATUS_SUCCESS:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
			};
		case UPDATE_INCIDENT_MENUFACTURING_STATUS_FAILURE:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
			};

		default:
			return initialState;
	}
};
