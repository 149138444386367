export const INCIDENT_QUALITY_REPORT_REQUEST = "INCIDENT_QUALITY_REPORT_REQUEST";
export const INCIDENT_QUALITY_REPORT_SUCCESS = "INCIDENT_QUALITY_REPORT_SUCCESS";
export const INCIDENT_QUALITY_REPORT_FAILURE = "INCIDENT_QUALITY_REPORT_FAILURE";
export const INCIDENT_QUALITY_REPORT_Reset = "INCIDENT_QUALITY_REPORT_Reset";

export const INCIDENT_QUALITY_REPORT_Details_REQUEST =
	"INCIDENT_QUALITY_REPORT_Details_REQUEST";
export const INCIDENT_QUALITY_REPORT_Details_SUCCESS =
	"INCIDENT_QUALITY_REPORT_Details_SUCCESS";
export const INCIDENT_QUALITY_REPORT_Details_FAILURE =
	"INCIDENT_QUALITY_REPORT_Details_FAILURE";

export const ADD_INCIDENT_QUALITY_REPORT_REQUEST =
	"ADD_INCIDENT_QUALITY_REPORT_REQUEST";
export const ADD_INCIDENT_QUALITY_REPORT_SUCCESS =
	"ADD_INCIDENT_QUALITY_REPORT_SUCCESS";
export const ADD_INCIDENT_QUALITY_REPORT_FAILURE =
	"ADD_INCIDENT_QUALITY_REPORT_FAILURE";

export const Update_INCIDENT_QUALITY_REPORT_REQUEST =
	"Update_INCIDENT_QUALITY_REPORT_REQUEST";
export const Update_INCIDENT_QUALITY_REPORT_SUCCESS =
	"Update_INCIDENT_QUALITY_REPORT_SUCCESS";
export const Update_INCIDENT_QUALITY_REPORT_FAILURE =
	"Update_INCIDENT_QUALITY_REPORT_FAILURE";

export const DELETE_INCIDENT_QUALITY_REPORT_REQUEST =
	"DELETE_INCIDENT_QUALITY_REPORT_REQUEST";
export const DELETE_INCIDENT_QUALITY_REPORT_SUCCESS =
	"DELETE_INCIDENT_QUALITY_REPORT_SUCCESS";
export const DELETE_INCIDENT_QUALITY_REPORT_FAILURE =
	"DELETE_INCIDENT_QUALITY_REPORT_FAILURE";
