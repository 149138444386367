import {
	ADD_INCIDENT_Supply_Chain_FAILURE,
	ADD_INCIDENT_Supply_Chain_REQUEST,
	ADD_INCIDENT_Supply_Chain_SUCCESS,
	DELETE_INCIDENT_Supply_Chain_FAILURE,
	DELETE_INCIDENT_Supply_Chain_REQUEST,
	DELETE_INCIDENT_Supply_Chain_SUCCESS,
	INCIDENT_Supply_Chain_FAILURE,
	INCIDENT_Supply_Chain_REQUEST,
	INCIDENT_Supply_Chain_Reset,
	INCIDENT_Supply_Chain_SUCCESS,
	UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_FAILURE,
	UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_REQUEST,
	UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_SUCCESS,
	Update_INCIDENT_Supply_Chain_FAILURE,
	Update_INCIDENT_Supply_Chain_REQUEST,
	Update_INCIDENT_Supply_Chain_SUCCESS,
} from "./IncidentSupplyChainConstant";

// Incident SupplyChain
export const IncidentSupplyChainReducer = (
	initialState = {
		IncidentSupplyChain: [],
		GetIncidentSupplyChainLoading: false,
		addIncidentSupplyChainLoading: false,
		deleteIncidentSupplyChainLoading: false,
		UpdateIncidentStatusLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident Supply_Chain
		case INCIDENT_Supply_Chain_REQUEST:
			return {
				...initialState,
				GetIncidentSupplyChainLoading: true,
			};
		case INCIDENT_Supply_Chain_SUCCESS:
			return {
				...initialState,
				IncidentSupply_Chain: action.payload.result.data,
				GetIncidentSupplyChainLoading: false,
			};
		case INCIDENT_Supply_Chain_FAILURE:
			return {
				...initialState,
				GetIncidentSupplyChainLoading: false,
				getError: action.payload,
			};
		case INCIDENT_Supply_Chain_Reset:
			return {
				...initialState,
				IncidentSupplyChain: [],
			};
		// Add Incident Supply_Chain
		case ADD_INCIDENT_Supply_Chain_REQUEST:
			return {
				...initialState,
				addIncidentSupplyChainLoading: true,
			};
		case ADD_INCIDENT_Supply_Chain_SUCCESS:
			return {
				...initialState,
				addIncidentSupplyChainLoading: false,
			};
		case ADD_INCIDENT_Supply_Chain_FAILURE:
			return {
				...initialState,
				addIncidentSupplyChainLoading: false,
				addError: action.payload,
			};

		// Update Incident Supply_Chain
		case Update_INCIDENT_Supply_Chain_REQUEST:
			return {
				...initialState,
				updateIncidentSupplyChainLoading: true,
			};
		case Update_INCIDENT_Supply_Chain_SUCCESS:
			return {
				...initialState,
				updateIncidentSupplyChainLoading: false,
			};
		case Update_INCIDENT_Supply_Chain_FAILURE:
			return {
				...initialState,
				updateIncidentSupplyChainLoading: false,
				updateError: action.payload,
			};

		// Delete Incident Supply_Chain
		case DELETE_INCIDENT_Supply_Chain_REQUEST:
			return {
				...initialState,
				deleteIncidentSupplyChainLoading: true,
			};
		case DELETE_INCIDENT_Supply_Chain_SUCCESS:
			return {
				...initialState,
				deleteIncidentSupplyChainLoading: false,
			};
		case DELETE_INCIDENT_Supply_Chain_FAILURE:
			return {
				...initialState,
				deleteIncidentSupplyChainLoading: false,
				deleteError: action.payload,
			};
		// Update Incident Supply Chain
		case UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_REQUEST:
			return {
				...initialState,
				UpdateIncidentStatusLoading: true,
			};
		case UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_SUCCESS:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
			};
		case UPDATE_INCIDENT_SUPPLY_CHAIN_STATUS_FAILURE:
			return {
				...initialState,
				UpdateIncidentStatusLoading: false,
				updateError: action.payload,
			};
		default:
			return initialState;
	}
};
