import {
	TIMELINE_FAILURE,
	TIMELINE_REQUEST,
	TIMELINE_SUCCESS,
} from "./TimeLineConstant";

export const TimeLineReducer = (
	state = {
		TimeLine: [],
	},
	action
) => {
	switch (action.type) {
		case TIMELINE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case TIMELINE_SUCCESS:
			return {
				...state,
				data: action.payload,
				TimeLineData: action.payload.result.data,
				TimeLineTotalItems: action.payload.result.totalItemsCount,
				loading: false,
			};

		case TIMELINE_FAILURE:
			return {
				...state,
				loading: false,
				getError: action.payload,
			};

		default:
			return state;
	}
};
