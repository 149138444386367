import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { connectRouter } from "connected-react-router";
import SettingsReducer from "./SettingsApp/settingsReducer";
import CommonReducer from "./Commons/commonReducer";
import {
	UserCredentialsReducer,
	userDetailsReducer,
	userReducer,
} from "./Personnel/User/userReducer";
import thunk from "redux-thunk";
import {
	departmentDetailsReducer,
	departmentReducer,
} from "./Personnel/Departments/departmentReducer";
import { positionsReducer } from "./Personnel/Positions/positionsReducer";
import { areaReducer } from "./siteManagement/Area/areasReducer";
import { userGroupReducer } from "./Personnel/userGroup/userGroupReducer";
import { visitorPermitsReducer } from "./VisitorControl/Permits/permitsReducer";
import {
	cardsReducer,
	userDashboardsReducer,
} from "./Dashboard/dashboardReducer";
import {
	rulesReducer,
	RuleDetailsReducer,
	RuleUserReducer,
	RuleUserGroupReducer,
	RuleAccessPointsReducer,
	RuleAccessPointGroupsReducer,
} from "./AccessControl/Rules/RulesReducer";
import {
	RoleDetailsReducer,
	RoleReducer,
} from "./Personnel/OperatorRoles/roleReducer";
import {
	CustomerReducer,
	CustomerDetailsReducer,
} from "./Owner/Customers/customerReducer";
import {
	scheduleDetailsReducer,
	schedulesReducer,
} from "./AccessControl/Schedules/SchedulesReducer";
import { buildingsReducer } from "./siteManagement/Buildings/buildingsReducer";
import {
	DevicesReducer,
	DevicesDetailsReducer,
} from "./Hardware/devicesReducer";
import { ReportReducer } from "./Report/reportsReducer";
import {
	accessPointLastNumberCardReducer,
	accessPointReducer,
} from "./AccessControl/AccessPoint/AccessPointReducer";
import { accessPointGroupsReducer } from "./AccessControl/AccessPointGroup/AccessPointGroupsReducer";
import { generalReducer } from "./Settings/General/generalReducer";
import { permitSettingsReducer } from "./Settings/permit/permitSettingsReducer";
import {
	userOwnerReducer,
	userOwnerDetailsReducer,
} from "./Owner/UserOwner/userOwnerReducer";
import { columnReducer } from "./Configuration/Columns/columnsReducer";
import { tableReducer } from "./Configuration/Tables/tablesReducer";
import { pINReadersReducer } from "./Settings/Devices/PINReaders/pinReducer";
import { DateAndTimeReducer } from "./Settings/Devices/SynchronizeDateAndTime/synchronizeD&TReducer";
import { ClearUserReducer } from "./Settings/Devices/ClearUser/clearUserRedux";
import { DeviceSettingsReducer } from "./Settings/Devices/DeviceSettings/deviceSettingsReducer";
import { ServerSettingsReducer } from "./Settings/Devices/ServerSettings/serverSettingRedux";
import { mapsReducer } from "./siteManagement/Maps/mapsReducer";
import {
	cardsOwnerReducer,
	OwnerDashboardsReducer,
} from "./Owner/DashboardOwmer/dashboardReducer";
import { logsReducer } from "./Settings/Logs/logsReducer";
import { SignUpReducer } from "./Auth/SignUp/signUpReducer";
import {
	DDLModulesReducer,
	PAYMENTReducer,
	paymentsDetailsReducer,
} from "./Payment/paymentReducer";
import { CleanUpReducer } from "./Settings/CleanUp/CleanUpReducer";
import { DemoSettingsReducer } from "./Configuration/DemoSettings/DemoSettingsReducer";
import {
	RuleAccessPointsCanteenReducer,
	RuleDetailsCanteenReducer,
	RuleUserCanteenReducer,
	rulesCanteenReducer,
} from "./CanteenControl/RulesCanteen/RulesCanteenReducer";
import { accessPointCanteenReducer } from "./CanteenControl/AccessPointsCanteen/AccessPointCanteenReducer";
import {
	mealDetailsCanteenReducer,
	mealsCanteenReducer,
} from "./CanteenControl/MealsCanteen/MealsCanteenReducer";
import { MessagesReducer } from "./Configuration/Messages/MessagesReducer";
import { visitorPermitEquipmentsReducer } from "./VisitorControl/Equipments/equipmentsReducer";
import { BackgroundRequestReducer } from "./Settings/BackgroundRequest/BackgroundRequestReducer";

import {
	selfEquipmentsReducer,
	selfVisitorReducer,
	LoginReducer,
	forgotPasswordReducer,
	recoveryPasswordReducer,
	accessPointByTenantReducer,
	selfCardReducer,
} from "./Auth/loginReducer";
import { accessPointVisitorReducer } from "./VisitorControl/AccessPointsVisitor/AccessPointVisitorReducer";
import {
	BranchesDetailsReducer,
	BranchesReducer,
} from "./Organizations/BranchesReducer";
import { TeamsDetailsReducer, TeamsReducer } from "./Team/teamReducer";
import {
	AssetTypeDetailsReducer,
	AssetTypeReducer,
} from "./AssetManagement/AssetType/AssetTypeReducer";
import {
	AssetDetailsReducer,
	AssetReducer,
} from "./AssetManagement/Asset/AssetReducer";
import {
	IncidentTypeDetailsReducer,
	IncidentTypeReducer,
} from "./IncidentControl/IncidentType/IncidentTypeRedux";
import {
	TaskTypeDetailsReducer,
	TaskTypeReducer,
} from "./Task/TaskType/TaskTypeReducer";
import {
	IncidentDetailsReducer,
	IncidentReducer,
} from "./IncidentControl/Incident/IncidentReducer";
import { TasksDetailsReducer, TasksReducer } from "./Task/Tasks/TasksReducer";
import { DamagedAssetsReducer } from "./IncidentControl/DamagedAssets/DamagedAssetsReducer";
import { IncidentAttachmentsReducer } from "./IncidentControl/IncidentAttachments/IncidentAttachmentsReducer";
import { TimeLineReducer } from "./TimeLineRequest/TimeLineReducer";
import { IncidentReportReducer } from "./IncidentControl/IncidentReport/IncidentReportReducer";
import { TasksMembersReducer } from "./Task/TaskMembersAssignments/TasksMembersReducer";
import { TasksReportReducer } from "./Task/TasksReportAttachments/TasksReportReducer";
import { IncidentProcurementReducer } from "./IncidentControl/IncidentProcurement/IncidentProcurementReducer";
import { IncidentSampleReducer } from "./IncidentControl/IncidentSample/IncidentSampleReducer";
import { IncidentSupplyChainReducer } from "./IncidentControl/IncidentSupplyChain/IncidentSupplyChainReducer";
import { IncidentMenufacturingReducer } from "./IncidentControl/IncidentMenufacturing/IncidentMenufacturingReducer";
import { IncidentQualityReportReducer } from "./IncidentControl/IncidentQualityReport/IncidentQualityReportReducer";
import { InjuredReducer } from "./IncidentControl/Injureds/InjuredReducer";
import { WitnessesReducer } from "./IncidentControl/Witnesses/WitnessReducer";
import { ErrorsReducer } from "./Configuration/Errors/ErrorsReducer";

const createBrowserHistory = require("history").createBrowserHistory;

export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		settings: SettingsReducer,
		common: CommonReducer,
		users: userReducer,
		departments: departmentReducer,
		positions: positionsReducer,
		areas: areaReducer,
		userGroups: userGroupReducer,
		visitorPermits: visitorPermitsReducer,
		userDetails: userDetailsReducer,
		departmentDetails: departmentDetailsReducer,
		userCredentials: UserCredentialsReducer,
		operatorRoles: RoleReducer,
		userDashboards: userDashboardsReducer,
		ownerDashboards: OwnerDashboardsReducer,
		userDashboardsItems: cardsReducer,
		rules: rulesReducer,
		operatorDetails: RoleDetailsReducer,
		owner: CustomerReducer,
		BackgroundRequest: BackgroundRequestReducer,
		ownerDetailes: CustomerDetailsReducer,
		schedules: schedulesReducer,
		schedulesDetails: scheduleDetailsReducer,
		buildings: buildingsReducer,
		device: DevicesReducer,
		deviceDetailes: DevicesDetailsReducer,
		reports: ReportReducer,
		accessPoint: accessPointReducer,
		accessPointGroups: accessPointGroupsReducer,
		ruleDetails: RuleDetailsReducer,
		RuleUser: RuleUserReducer,
		RuleUserGroups: RuleUserGroupReducer,
		RulesAccessPoints: RuleAccessPointsReducer,
		RulesAccessPointGroups: RuleAccessPointGroupsReducer,
		generalSettings: generalReducer,
		permitSettings: permitSettingsReducer,
		UserOwnerReduser: userOwnerReducer,
		UserOwnerDetailesReduser: userOwnerDetailsReducer,
		columns: columnReducer,
		tables: tableReducer,
		messagesReducer: MessagesReducer,
		pINReadersReducer: pINReadersReducer,
		DateAndTimeReducer: DateAndTimeReducer,
		ClearUserReducer: ClearUserReducer,
		DeviceSettingsReducer: DeviceSettingsReducer,
		ServerSettingsReducer: ServerSettingsReducer,
		maps: mapsReducer,
		cardsOwnerReducer: cardsOwnerReducer,
		logsReducer: logsReducer,
		SignUpReducer: SignUpReducer,
		PAYMENTReducer: PAYMENTReducer,
		paymentsDetailsReducer: paymentsDetailsReducer,
		CleanUpReducer: CleanUpReducer,
		DemoSettingsReducer: DemoSettingsReducer,
		DDLModulesReducer: DDLModulesReducer,
		rulesCanteen: rulesCanteenReducer,
		ruleDetailsCanteen: RuleDetailsCanteenReducer,
		ruleUserCanteen: RuleUserCanteenReducer,
		ruleAccessPointsCanteen: RuleAccessPointsCanteenReducer,
		mealsCanteen: mealsCanteenReducer,
		mealDetailsCanteen: mealDetailsCanteenReducer,
		accessPointCanteen: accessPointCanteenReducer,
		visitorPermitEquipments: visitorPermitEquipmentsReducer,
		selfVisitorReducer: selfVisitorReducer,
		selfEquipmentsReducer: selfEquipmentsReducer,
		LoginReducer: LoginReducer,
		forgotPasswordReducer: forgotPasswordReducer,
		recoveryPasswordReducer: recoveryPasswordReducer,
		accessPointByTenantReducer: accessPointByTenantReducer,
		accessPointLastNumberCardReducer: accessPointLastNumberCardReducer,
		selfCardReducer: selfCardReducer,
		accessPointVisitor: accessPointVisitorReducer,
		Branches: BranchesReducer,
		BranchesDetails: BranchesDetailsReducer,
		Teams: TeamsReducer,
		TeamsDetails: TeamsDetailsReducer,
		AssetType: AssetTypeReducer,
		AssetTypeDetails: AssetTypeDetailsReducer,
		Asset: AssetReducer,
		AssetDetails: AssetDetailsReducer,
		IncidentType: IncidentTypeReducer,
		IncidentTypeDetails: IncidentTypeDetailsReducer,
		TaskType: TaskTypeReducer,
		TaskTypeDetails: TaskTypeDetailsReducer,
		Incident: IncidentReducer,
		IncidentDetails: IncidentDetailsReducer,
		TasksDetails: TasksDetailsReducer,
		Tasks: TasksReducer,
		TaskMembers: TasksMembersReducer,
		DamagedAssets: DamagedAssetsReducer,
		Witnesses: WitnessesReducer,
		Injured: InjuredReducer,
		TimeLine: TimeLineReducer,
		IncidentReport: IncidentReportReducer,
		IncidentAttachments: IncidentAttachmentsReducer,
		TasksReportReducer: TasksReportReducer,
		IncidentProcurementReducer: IncidentProcurementReducer,
		IncidentSampleReducer: IncidentSampleReducer,
		IncidentSupplyChainReducer: IncidentSupplyChainReducer,
		IncidentMenufacturingReducer: IncidentMenufacturingReducer,
		IncidentQualityReportReducer: IncidentQualityReportReducer,
		ErrorsReducer: ErrorsReducer
	});

export default function configureStore(preloadedState = {}) {
	return createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		composeWithDevTools(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				...middlewares
			)
		)
	);
}
