import {
	ADD_INCIDENT_QUALITY_REPORT_FAILURE,
	ADD_INCIDENT_QUALITY_REPORT_REQUEST,
	ADD_INCIDENT_QUALITY_REPORT_SUCCESS,
	DELETE_INCIDENT_QUALITY_REPORT_FAILURE,
	DELETE_INCIDENT_QUALITY_REPORT_REQUEST,
	DELETE_INCIDENT_QUALITY_REPORT_SUCCESS,
	INCIDENT_QUALITY_REPORT_FAILURE,
	INCIDENT_QUALITY_REPORT_REQUEST,
	INCIDENT_QUALITY_REPORT_Reset,
	INCIDENT_QUALITY_REPORT_SUCCESS,
	Update_INCIDENT_QUALITY_REPORT_FAILURE,
	Update_INCIDENT_QUALITY_REPORT_REQUEST,
	Update_INCIDENT_QUALITY_REPORT_SUCCESS,
} from "./IncidentQualityReportConstant";

// Incident QualityReport
export const IncidentQualityReportReducer = (
	initialState = {
		IncidentQualityReport: [],
		GetIncidentQualityReportLoading: false,
		addIncidentQualityReportLoading: false,
		deleteIncidentQualityReportLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident QualityReport
		case INCIDENT_QUALITY_REPORT_REQUEST:
			return {
				...initialState,
				GetIncidentQualityReportLoading: true,
			};
		case INCIDENT_QUALITY_REPORT_SUCCESS:
			return {
				...initialState,
				IncidentQualityReport: action.payload.result.data,
				GetIncidentQualityReportLoading: false,
			};
		case INCIDENT_QUALITY_REPORT_FAILURE:
			return {
				...initialState,
				GetIncidentQualityReportLoading: false,
				getError: action.payload,
			};
		case INCIDENT_QUALITY_REPORT_Reset:
			return {
				...initialState,
				IncidentQualityReport: [],
			};
		// Add Incident QualityReport
		case ADD_INCIDENT_QUALITY_REPORT_REQUEST:
			return {
				...initialState,
				addIncidentQualityReportLoading: true,
			};
		case ADD_INCIDENT_QUALITY_REPORT_SUCCESS:
			return {
				...initialState,
				addIncidentQualityReportLoading: false,
			};
		case ADD_INCIDENT_QUALITY_REPORT_FAILURE:
			return {
				...initialState,
				addIncidentQualityReportLoading: false,
				addError: action.payload,
			};

		// Update Incident QualityReport
		case Update_INCIDENT_QUALITY_REPORT_REQUEST:
			return {
				...initialState,
				updateIncidentQualityReportLoading: true,
			};
		case Update_INCIDENT_QUALITY_REPORT_SUCCESS:
			return {
				...initialState,
				updateIncidentQualityReportLoading: false,
			};
		case Update_INCIDENT_QUALITY_REPORT_FAILURE:
			return {
				...initialState,
				updateIncidentQualityReportLoading: false,
				updateError: action.payload,
			};

		// Delete Incident QualityReport
		case DELETE_INCIDENT_QUALITY_REPORT_REQUEST:
			return {
				...initialState,
				deleteIncidentQualityReportLoading: true,
			};
		case DELETE_INCIDENT_QUALITY_REPORT_SUCCESS:
			return {
				...initialState,
				deleteIncidentQualityReportLoading: false,
			};
		case DELETE_INCIDENT_QUALITY_REPORT_FAILURE:
			return {
				...initialState,
				deleteIncidentQualityReportLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};
