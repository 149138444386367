export const INCIDENT_Sample_REQUEST = "INCIDENT_Sample_REQUEST";
export const INCIDENT_Sample_SUCCESS = "INCIDENT_Sample_SUCCESS";
export const INCIDENT_Sample_FAILURE = "INCIDENT_Sample_FAILURE";
export const INCIDENT_Sample_Reset = "INCIDENT_Sample_Reset";

export const INCIDENT_Sample_Details_REQUEST =
	"INCIDENT_Sample_Details_REQUEST";
export const INCIDENT_Sample_Details_SUCCESS =
	"INCIDENT_Sample_Details_SUCCESS";
export const INCIDENT_Sample_Details_FAILURE =
	"INCIDENT_Sample_Details_FAILURE";

export const ADD_INCIDENT_Sample_REQUEST =
	"ADD_INCIDENT_Sample_REQUEST";
export const ADD_INCIDENT_Sample_SUCCESS =
	"ADD_INCIDENT_Sample_SUCCESS";
export const ADD_INCIDENT_Sample_FAILURE =
	"ADD_INCIDENT_Sample_FAILURE";

export const Update_INCIDENT_Sample_REQUEST =
	"Update_INCIDENT_Sample_REQUEST";
export const Update_INCIDENT_Sample_SUCCESS =
	"Update_INCIDENT_Sample_SUCCESS";
export const Update_INCIDENT_Sample_FAILURE =
	"Update_INCIDENT_Sample_FAILURE";

export const DELETE_INCIDENT_Sample_REQUEST =
	"DELETE_INCIDENT_Sample_REQUEST";
export const DELETE_INCIDENT_Sample_SUCCESS =
	"DELETE_INCIDENT_Sample_SUCCESS";
export const DELETE_INCIDENT_Sample_FAILURE =
	"DELETE_INCIDENT_Sample_FAILURE";
